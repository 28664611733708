import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyCzJv4EGRsp4kAiSyIofME5a7KJig8YyH4",
  authDomain: "eduardoprado-dev.firebaseapp.com",
  projectId: "eduardoprado-dev",
  storageBucket: "eduardoprado-dev.appspot.com",
  messagingSenderId: "24733881124",
  appId: "1:24733881124:web:8b7eb535686f1feaecd9b1",
  measurementId: "G-X95WT0CVWC"
};

initializeApp(firebaseConfig);
export const analytics = getAnalytics();
